import { Doughnut, mixins } from 'vue-chartjs';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
 
export default {
    extends: Doughnut,
    mixins: [mixins.reactiveProp],
    props: ["chartData", "options"],
    mounted () {
        // this.addPlugin(ChartDataLabels);
        this.renderChart(
            this.chartData,
            this.options
        );
    }
}